.card-container {
	background-color: white;
	height: auto;
	border-radius: 14px;
	box-shadow: 0px 10px 30px hsl(185, 75%, 35%);
    width: 40%;
    margin: 5% auto;
    color: black;
}

.header-profile {
	background-position: 0px 0px;
	background-repeat: no-repeat;
	background-size: contain;
	text-align: center;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
}

/*
img {
	margin: auto;
	width: 100px;
	border: solid white 4px;
	border-radius: 50%;
	margin-top: 75px;
}
*/

.bold-text {
	font-weight: bold;
	font-size: 1.1rem;
	text-align: center;
	padding: 10px 20px 0px 20px;
}

.normal-header {
	font-weight: bold;
	font-size: 2rem;
	color: black;
	text-align: center;
	padding-bottom: 10px;
}

.normal-text {
	font-weight: normal;
	font-size: 1rem;
	color: black;
	text-align: center;
	padding-bottom: 10px;
}

/* SOCIAL STATS */
.smaller-text {
	font-weight: normal;
	font-size: 0.7rem;
	color: hsl(0, 0%, 50%);
	letter-spacing: 1px;
	padding-bottom: 20px;
	line-height: 5px;
    margin-left: 0px;
}

.social-container {
	border-top: solid rgb(206, 206, 206) 1px;
    margin-top: 2%;
}

.followers {
	display: flex;
}

.followers > .header-profile {
    flex: 1;
    margin : 0;
    width: 50px;  
}

.followers > .header-profile > #level{
    flex: 1;
    margin-top : 5%;
    margin-bottom: 0;
}

.followers > .header-profile >  .bold-text{
    margin: 0;
    padding: 0;
    text-align: center;
}

.followers > h1 {
    flex: 1;
    padding: 0;
    margin: 10%;
}

.point-text{
    text-align: center;
}

.box-style{
    box-shadow: inset 0 -5px 0 grey; line-height: 2px
}
